import Flex from 'components/Box/Flex'
import ButtonGroupItem from 'components/Button/ButtonGroupItem'
import Heading from 'components/Heading/Heading'
import Modal, { ModalBody, ModalHeader, ModalProps } from 'components/Modal'
import React, { useState } from 'react'
import { usePoolFromCode } from 'state/pools/hooks'
import styled from 'styled-components'
import PoolForm from './PoolForm'
import WithdrawForm from './WithdrawForm'

const StyledButtonGroup = styled(Flex)`
  border-radius: ${({ theme }) => theme.radii.small};
`

enum View {
  Supply,
  Withdraw,
  Borrow,
  Repay,
}

const title = {
  [View.Supply]: 'Supply',
  [View.Withdraw]: 'Withdraw',
}
const PoolDetailsModal: React.FC<ModalProps<{ code: string }>> = ({ onDismiss, data: { code } }) => {
  const pool = usePoolFromCode(code)
  const [view, setView] = useState(View.Supply)

  return (
    <Modal onDismiss={onDismiss} maxWidth="480px !important" minHeight="534px !important">
      <ModalHeader pb="0 !important">
        <Heading>
          {title[view]} {pool.token.name}
        </Heading>
      </ModalHeader>
      <ModalBody minHeight={['auto', '570px', '610px']}>
        <StyledButtonGroup mb="24px">
          <ButtonGroupItem $active={view === View.Supply} onClick={() => setView(View.Supply)}>
            Supply
          </ButtonGroupItem>
          <ButtonGroupItem $active={view === View.Withdraw} onClick={() => setView(View.Withdraw)}>
            Withdraw
          </ButtonGroupItem>
        </StyledButtonGroup>

        {view === View.Supply && <PoolForm pool={pool} />}
        {view === View.Withdraw && <WithdrawForm pool={pool} />}
      </ModalBody>
    </Modal>
  )
}

export default PoolDetailsModal
