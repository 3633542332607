import React, { useCallback, useMemo, useState } from 'react'

import Text from 'components/Text/Text'
import TokenInput from 'components/TokenInput'
import { BIG_ZERO } from 'config'
import { DeserializedPool } from 'config/types/pool'
import { TokenAmount } from 'config/types/tokenAmount'
import Column from 'layout/components/Column'
import { RowBetween } from 'layout/components/Row'
import { formatLocalAndDisplayBalanceEthValue } from 'utils/formatBalance'
import { getBurnToken, getTotalAvailiableWithdraw } from 'utils/reciept-base'
import PoolDetailsStats from './PoolDetailsStats'
import WithdrawAction from './WithdrawAction'

const WithdrawForm: React.FC<{ pool: DeserializedPool }> = ({ pool }) => {
  const [val, setVal] = useState('')
  const tokenBalance = useMemo(() => {
    return new TokenAmount(pool.token, pool.userData.withdrawBalance.toString(10))
  }, [pool])

  const handleChange = useCallback(
    (value: string) => {
      setVal(value)
    },
    [setVal],
  )

  const totalAvailiableWithdraw = getTotalAvailiableWithdraw(pool.totalAvailable)
  const burn = getBurnToken(val || BIG_ZERO, pool.bearingRate)

  return (
    <Column flex={1}>
      <Column flex={1}>
        <TokenInput
          maxBalance={totalAvailiableWithdraw.toString()}
          value={val}
          token={pool.token}
          balance={tokenBalance}
          onUserInput={handleChange}
        />
        <PoolDetailsStats pool={pool}>
          <RowBetween>
            <Text small fontWeight="400" color="textSubtle">
              Maximum Withdraw
            </Text>
            <Text>{`${formatLocalAndDisplayBalanceEthValue(totalAvailiableWithdraw)} ${pool.token.symbol}`}</Text>
          </RowBetween>

          <RowBetween mt="8px">
            <Text small fontWeight="400" color="textSubtle">
              Burn
            </Text>
            <Text>{`${formatLocalAndDisplayBalanceEthValue(burn)} ${pool.suppliedToken.symbol}`}</Text>
          </RowBetween>
        </PoolDetailsStats>
      </Column>

      {/* <Box my="24px">
        <YieldForecast amountDeposit={new BigNumber(val).gt(0) ? new BigNumber(val) : BIG_ZERO} pool={pool} />
      </Box> */}

      <WithdrawAction
        pool={pool}
        amount={val}
        onSuccess={() => {
          setVal('')
        }}
      />
    </Column>
  )
}

export default WithdrawForm
