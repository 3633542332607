import { useMemo } from 'react'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { DeserializedPool } from 'config/types/pool'
import { TokenAmount } from 'config/types/tokenAmount'
import { getLiquidityPoolAddress } from 'utils/addressHelpers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

export const useApproveLiquidityPool = (amount: string, pool: DeserializedPool) => {
  const { chainId } = useActiveWeb3React()
  const tokenAmount = useMemo(() => new TokenAmount(pool.token, amount), [amount])
  const [approval, approveCallback] = useApproveCallback(tokenAmount, getLiquidityPoolAddress(chainId))

  const showApprove =
    approval === ApprovalState.NOT_APPROVED ||
    approval === ApprovalState.APPROVED_LESS_THAN_INPUT_AMOUNT ||
    approval === ApprovalState.CONFIRMING ||
    approval === ApprovalState.PENDING

  return { showApprove, approval, approveCallback }
}
