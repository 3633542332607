import Box from 'components/Box/Box'
import React, { useCallback, useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import ConnectButton from 'components/ConnectButton'
import { useAppDispatch } from 'state'
import BigNumber from 'bignumber.js'
import { DeserializedPool } from 'config/types/pool'
import { fetchPoolsPublicDataAsync, fetchPoolUserDataAsync } from 'state/pools/actions'
import { useWithdraw } from '../hooks/useWithdraw'
import { SubmitTxnButton } from 'components/Button/SubmitTxnButton'
import { RESERVE_FACTOR } from 'config'

export interface WithdrawActionProps {
  amount: string
  pool: DeserializedPool
  onSuccess?: () => void
}

export const WithdrawAction: React.FC<WithdrawActionProps> = ({ amount, pool, onSuccess }) => {
  const { account, isWrongChainId, chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { onWithdraw } = useWithdraw(pool)
  const bigAmount = useMemo(() => new BigNumber(amount || 0), [amount])

  const handleAfterWithdraw = useCallback(() => {
    dispatch(fetchPoolsPublicDataAsync({ codes: [pool.code], chainId }))
    dispatch(fetchPoolUserDataAsync({ account, codes: [pool.code], chainId }))
    if (onSuccess) onSuccess()
  }, [])

  const handleWithdraw = useCallback(() => {
    const bearingAmount = new BigNumber(amount).dividedBy(pool.bearingRate).toNumber()
    return onWithdraw(bearingAmount.toString(10))
  }, [amount])

  const totalAvailiableWithdraw = pool?.totalAvailable?.multipliedBy(RESERVE_FACTOR)
  const isOverAvailiable = bigAmount.gt(totalAvailiableWithdraw)

  const error =
    bigAmount.eq(0) || !bigAmount.isFinite
      ? 'Enter amount'
      : bigAmount.gt(pool.userData.withdrawBalance)
      ? 'Insufficient Balance'
      : isOverAvailiable
      ? 'Withdrawal amount exceeds limit'
      : ''

  return (
    <Box mt="24px">
      {!account || isWrongChainId ? (
        <ConnectButton width="100%" height="44px" />
      ) : (
        <SubmitTxnButton
          variant="primary"
          base={`Withdraw ${amount} ${pool.token.name}`}
          call={handleWithdraw}
          callback={handleAfterWithdraw}
          error={error}
          height="44px"
          width="100%"
        >
          Withdraw
        </SubmitTxnButton>
      )}
    </Box>
  )
}

export default React.memo(WithdrawAction)
