import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import ApproveButton from 'components/Button/ApproveButton'
import SubmitTxnButton from 'components/Button/SubmitTxnButton'
import ConnectButton from 'components/ConnectButton'
import { DeserializedPool } from 'config/types/pool'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React, { useCallback, useMemo } from 'react'
import { useAppDispatch } from 'state'
import { useApproveLiquidityPool } from '../hooks/useApproveLiquidityPool'
import { useSupply } from '../hooks/useSupply'
import { fetchPoolUserDataAsync, fetchPoolsPublicDataAsync } from 'state/pools/actions'

interface PoolActionProps {
  amount: string
  pool: DeserializedPool
  onSuccess?: () => void
}

const PoolAction: React.FC<PoolActionProps> = ({ amount, pool, onSuccess }) => {
  const { account, isWrongChainId, chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { onSupply } = useSupply(pool)

  const bigAmount = useMemo(() => new BigNumber(amount || 0), [amount])

  const { approval, approveCallback, showApprove } = useApproveLiquidityPool(amount, pool)

  const handleAfterSupply = useCallback(() => {
    dispatch(fetchPoolsPublicDataAsync({ codes: [pool.code], chainId }))
    dispatch(fetchPoolUserDataAsync({ account, codes: [pool.code], chainId }))

    if (onSuccess) onSuccess()
  }, [])

  const handleSupply = useCallback(() => {
    return onSupply(amount)
  }, [amount])

  const error =
    bigAmount.eq(0) || !bigAmount.isFinite
      ? 'Enter amount'
      : bigAmount.gt(pool.userData.tokenBalance?.toFixed(18))
      ? 'Insufficient Balance'
      : ''

  return (
    <Box mt="24px">
      {!account || isWrongChainId ? (
        <ConnectButton width="100%" height="44px" />
      ) : showApprove ? (
        <ApproveButton
          variant="primary"
          approveCallback={approveCallback}
          height="44px"
          width="100%"
          approval={approval}
        >
          Enable
        </ApproveButton>
      ) : (
        <SubmitTxnButton
          variant="primary"
          call={handleSupply}
          callback={handleAfterSupply}
          error={error}
          base={`Supply ${amount} ${pool.token.name}`}
          height="44px"
          width="100%"
        >
          Supply
        </SubmitTxnButton>
      )}
    </Box>
  )
}

export default React.memo(PoolAction)
