import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Grid from 'components/Box/Grid'
import styled from 'styled-components'

export const TableCell = styled(Flex)`
  align-items: center;
  height: 48px;
`
export const TableHeader = styled(Grid)`
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 12px;
  margin: 0px -12px;
  padding: 12px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0px -24px;
    padding: 12px 24px;

    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const TableRow = styled(Box)`
  margin: 0px -12px;
  transition: 0.2s;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0px -24px;
  }
`

export const TableRowInnerContainer = styled(Grid)`
  padding: 16px 12px !important;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.input};
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 16px 24px !important;
  }
`

export const GridColPoolTable = styled(TableRowInnerContainer)`
  grid-template-columns: 1fr 3fr 40px;
  width: 100%;

  #total-borrow,
  #liquidity,
  #your-suplly {
    display: none !important;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: 1fr 2fr 2fr 90px;

    #your-suplly {
      display: flex !important;
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 100px;

    #total-borrow {
      display: flex !important;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    #liquidity {
      display: flex !important;
    }
    grid-template-columns: 2fr 80px 2fr 2fr 2fr 1fr;
  }
`
