import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Grid from 'components/Box/Grid'
import Text from 'components/Text/Text'
import { DeserializedPool } from 'config/types/pool'
import Column from 'layout/components/Column'
import React from 'react'
import { formatLocalAndDisplayBalanceEthValue } from 'utils/formatBalance'

const durations = [
  {
    title: '30 days',
    month: 1,
  },
  {
    title: '90 days',
    month: 3,
  },
  {
    title: '1 year',
    month: 12,
  },
]

const YieldForecast: React.FC<{ pool: DeserializedPool; amountDeposit: BigNumber }> = ({ pool, amountDeposit }) => {
  const yields = durations.map((duration) => {
    const earningAtMonth = amountDeposit
      .multipliedBy(pool.apy.supply)
      .dividedBy(100) // to percent
      .dividedBy(12) // 1 year = 12 months
      .multipliedBy(duration.month)

    return {
      title: duration.title,
      earning: earningAtMonth,
      netProfit: amountDeposit.plus(earningAtMonth),
    }
  })
  return (
    <Box>
      <Text fontSize="16px" mb="8px">
        Yield Forecast
      </Text>

      <Column>
        <Grid gridGap="8px" gridTemplateColumns="1fr 1fr 1fr" mb="4px">
          <Text small color="textSubtle">
            Duration
          </Text>
          <Text small color="textSubtle" textAlign="right">
            Earnings
          </Text>
          <Text small color="textSubtle" textAlign="right">
            Net Value
          </Text>
        </Grid>
        <Column>
          {yields.map((yieldForecast) => {
            return (
              <>
                <Grid gridGap="8px" gridTemplateColumns="1fr 1fr 1fr" my="4px">
                  <Text color="textTertiary">{yieldForecast.title}</Text>
                  <Text
                    color="textTertiary"
                    display="flex"
                    style={{
                      justifyContent: 'flex-end',
                    }}
                    textAlign="right"
                  >
                    {formatLocalAndDisplayBalanceEthValue(yieldForecast.earning, 4)}
                    <Text fontWeight="400" color="textTertiary" small ml="4px">
                      {pool.token.symbol}
                    </Text>
                  </Text>
                  <Text
                    color="textTertiary"
                    display="flex"
                    style={{
                      justifyContent: 'flex-end',
                    }}
                    textAlign="right"
                  >
                    {formatLocalAndDisplayBalanceEthValue(yieldForecast.netProfit, 4)}{' '}
                    <Text fontWeight="400" color="textTertiary" small ml="4px">
                      {pool.token.symbol}
                    </Text>
                  </Text>
                </Grid>
              </>
            )
          })}
        </Column>
      </Column>
    </Box>
  )
}

export default YieldForecast
