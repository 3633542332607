import BigNumber from 'bignumber.js'
import { DeserializedPool } from 'config/types/pool'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useLiquidityPoolContract } from 'hooks/useContract'
import { useCallback } from 'react'
import { getDecimalAmount } from 'utils/formatBalance'

export const useSupply = (pool: DeserializedPool) => {
  const { chainId, account } = useActiveWeb3React()
  const liquidityPoolContract = useLiquidityPoolContract(chainId, true)

  const handleSupply = useCallback(
    async (amount: string) => {
      return liquidityPoolContract.addLiquidity(
        pool.token.address,
        getDecimalAmount(new BigNumber(amount), pool.token.decimals).toString(10),
        account,
      )
    },
    [liquidityPoolContract],
  )

  return { onSupply: handleSupply }
}
