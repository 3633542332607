import Grid from 'components/Box/Grid'
import Text from 'components/Text'
import { RowFixed } from 'layout/components/Row'
import React from 'react'
import { usePools } from 'state/pools/hooks'
import PoolRow from './PoolRow'
import { GridColPoolTable, TableRow } from './styled'

const PoolTable: React.FC<{ redirect?: boolean }> = ({ redirect }) => {
  const pools = usePools()

  return (
    <Grid>
      <TableRow>
        <GridColPoolTable>
          <Text color="textSubtle">Asset</Text>

          <RowFixed ml="auto">
            <Text color="textSubtle" textAlign="right">
              Earn APY
            </Text>
          </RowFixed>

          <RowFixed ml="auto" id="total-borrow">
            <Text color="textSubtle" textAlign="right">
              Borrowed
            </Text>
          </RowFixed>

          <RowFixed ml="auto" id="your-suplly">
            <Text color="textSubtle" textAlign="right">
              Your Supply
            </Text>
          </RowFixed>

          <RowFixed ml="auto" id="liquidity">
            <Text color="textSubtle" textAlign="right">
              Liquidity
            </Text>
          </RowFixed>

          <Text color="textSubtle" textAlign="right"></Text>
        </GridColPoolTable>
      </TableRow>

      {pools?.data?.map((pool) => (
        <PoolRow key={`pool-row-${pool.code}`} pool={pool} redirect={redirect} />
      ))}
    </Grid>
  )
}

export default PoolTable
