import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Button from 'components/Button/Button'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Text from 'components/Text'
import { DeserializedPool } from 'config/types/pool'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useModal from 'hooks/useModal'
import Column from 'layout/components/Column'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import DropdownIcon from 'svgs/dropdown.svg'
import { colors } from 'theme/colors'
import { displayBalanceEthValue, formatLocalAndDisplayBalanceEthValue } from 'utils/formatBalance'
import PoolDetailsModal from './PoolDetailsModal'
import { GridColPoolTable, TableCell, TableRow } from './styled'
import { RowCenter } from 'layout/components/Row'
import { useRouter } from 'next/router'
import { urlRoute } from 'config/route'
import BigNumber from 'bignumber.js'
import { getAmountSuppliedBalance } from 'utils/reciept-base'

const StyledWrapArrow = styled(Box)<{ expand: boolean }>`
  svg {
    fill: #fff;
  }

  transition: 0.5s all;

  ${({ expand }) =>
    !expand &&
    css`
      transform: rotate(-90deg);
    `}
`

const StyledRedirect = styled(RowCenter)`
  svg {
    fill: #fff !important;
  }
  transform: rotate(-90deg);

  background: ${({ theme }) => theme.colors.failure} !important;
  border-radius: 50%;

  width: 32px;
  height: 32px;
  flex: inherit;
`
const formatFiat = (value: BigNumber | string | number) => {
  return Number(value.toString()).toLocaleString('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 2,
  })
}
const PoolRow: React.FC<{ pool: DeserializedPool; redirect?: boolean } & BoxProps> = ({ pool, redirect, ...props }) => {
  const { isXxl } = useMatchBreakpoints()
  const [expand, setExpand] = useState(false)
  const router = useRouter()

  const [onPresentConnectModal] = useModal(PoolDetailsModal)

  const handleClickItem = () => {
    if (isXxl) {
      onPresentConnectModal({ code: pool.code })
    } else {
      setExpand(!expand)
    }
  }

  const YourSupply = () => (
    <Flex flexDirection="column">
      <Text textAlign="right">
        {formatLocalAndDisplayBalanceEthValue(
          getAmountSuppliedBalance(pool.userData.suppliedBalance, pool.bearingRate),
          4,
        )}
      </Text>
      <Text fontSize="12px" color="textSubtle" textAlign="right">
        $
        {formatFiat(
          getAmountSuppliedBalance(pool.userData.suppliedBalance, pool.bearingRate).multipliedBy(pool.tokenInUsdPrice),
        )}
      </Text>
    </Flex>
  )

  const displayLiquidity = formatLocalAndDisplayBalanceEthValue(pool.totalSupplied, 4)
  const displayLiquidityInUsd = formatFiat(pool.totalSupplied.multipliedBy(pool.tokenInUsdPrice))

  const displayTotalBorrow = formatLocalAndDisplayBalanceEthValue(pool.totalBorrowed, 4)
  const displayTotalBorrowInUsd = formatFiat(pool.totalBorrowed.multipliedBy(pool.tokenInUsdPrice))

  const displayEarnAPY = formatLocalAndDisplayBalanceEthValue(pool.apy.supply, 2)

  return (
    <TableRow background={expand && ['rgba(0,0,0,0.15)', '', '', '', 'unset']} {...props}>
      <GridColPoolTable
        onClick={
          redirect
            ? () => {
                router.push(urlRoute.pools().route)
              }
            : handleClickItem
        }
      >
        {/* Assets */}
        <TableCell>
          <Box width="36px" height="36px">
            <CurrencyLogo size={36} token={pool.token} />
          </Box>
          <Flex ml="12px" flexDirection="column">
            <Text>{pool.token.name}</Text>
            <Text fontSize="12px" color="textSubtle">
              ${displayBalanceEthValue(pool.tokenInUsdPrice)}
            </Text>
          </Flex>
        </TableCell>

        {/* Earn APY */}
        <TableCell justifyContent="flex-end">
          <Text>{displayEarnAPY}%</Text>
        </TableCell>

        {/* Total borrowed */}
        <TableCell justifyContent="flex-end" id="total-borrow">
          <Flex flexDirection="column">
            <Text textAlign="right">{displayTotalBorrow}</Text>
            <Text textAlign="right" fontSize="12px" color="textSubtle">
              ${displayTotalBorrowInUsd}
            </Text>
          </Flex>
        </TableCell>

        {/* Borrow apy */}
        {/* <TableCell justifyContent="flex-end" display={['none !important', '', 'flex !important']}>
          <Text>{pool.apy.borrow}%</Text>
        </TableCell> */}

        {/* Your Supply */}
        <TableCell justifyContent="flex-end" id="your-suplly">
          <YourSupply />
        </TableCell>

        {/* Liquidity */}
        <TableCell justifyContent="flex-end" id="liquidity">
          <Flex flexDirection="column">
            <Text textAlign="right">{displayLiquidity}</Text>
            <Text fontSize="12px" color="textSubtle" textAlign="right">
              ${displayLiquidityInUsd}
            </Text>
          </Flex>
        </TableCell>

        {/* Collateral toggle */}
        {redirect ? (
          <TableCell display={['flex !important']} justifyContent="flex-end">
            <StyledRedirect>
              <DropdownIcon />
            </StyledRedirect>
          </TableCell>
        ) : (
          <>
            <TableCell display={['none !important', '', '', '', '', 'flex !important']} justifyContent="flex-end">
              <Button width="100%" maxWidth="100px">
                Manage
              </Button>
            </TableCell>

            {/* Expand cell */}
            <TableCell display={['flex !important', '', '', '', '', 'none !important']} justifyContent="flex-end">
              <StyledWrapArrow expand={expand}>
                <DropdownIcon />
              </StyledWrapArrow>
            </TableCell>
          </>
        )}
      </GridColPoolTable>

      <Box
        width="100%"
        py={expand ? ['', '', '12px', '', '', '0px'] : '0px'}
        maxHeight={expand ? ['500px', '', '', '', '', '0px'] : '0px'}
        overflow="hidden"
        style={{
          transition: '0.5s',
        }}
      >
        <Box
          padding="24px"
          maxWidth="480px"
          margin="auto"
          border={['', '', `1px solid ${colors.textDisabled}`]}
          borderRadius="12px"
        >
          <Flex
            my="8px"
            justifyContent="space-between"
            alignItems="center"
            display={['flex', '', '', 'none !important']}
          >
            <Text small fontWeight="400" color="textSubtle">
              Your Supply
            </Text>
            <YourSupply />
          </Flex>

          <Flex
            my="8px"
            justifyContent="space-between"
            alignItems="center"
            display={['flex', '', '', 'none !important']}
          >
            <Text small fontWeight="400" color="textSubtle">
              Supply APY
            </Text>
            <Text>{displayEarnAPY}%</Text>
          </Flex>
          <Flex
            my="8px"
            justifyContent="space-between"
            alignItems="center"
            display={['flex', '', '', 'none !important']}
          >
            <Text small fontWeight="400" color="textSubtle">
              Borrow APY
            </Text>
            <Text>{pool.apy.borrow}%</Text>
          </Flex>

          <Column my="8px">
            <Flex justifyContent="space-between" alignItems="center" mt="6px">
              <Text small fontWeight="400" color="textSubtle">
                Total Borrowed
              </Text>
              <Text> {displayTotalBorrow}</Text>
            </Flex>

            <Flex justifyContent="flex-end" alignItems="center">
              <Text small color="textSubtle">
                ~ ${displayTotalBorrowInUsd}
              </Text>
            </Flex>
          </Column>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            mt="8px"
            display={['flex', '', '', 'none !important']}
          >
            <Text small fontWeight="400" color="textSubtle">
              Liquidity
            </Text>
            <Text> {displayLiquidity}</Text>
          </Flex>

          <Flex justifyContent="flex-end" alignItems="center" display={['flex', '', '', 'none !important']}>
            <Text small color="textSubtle">
              ${displayLiquidityInUsd}
            </Text>
          </Flex>

          <Button mt="24px" width="100%" onClick={() => onPresentConnectModal({ code: pool.code })}>
            Manage
          </Button>
        </Box>
      </Box>
    </TableRow>
  )
}

export default PoolRow
