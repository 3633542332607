import React from 'react'
import Box from 'components/Box/Box'
import { DeserializedPool } from 'config/types/pool'
import Flex from 'components/Box/Flex'
import Text from 'components/Text/Text'
import { RowBetween } from 'layout/components/Row'
import Column from 'layout/components/Column'
import { displayBalanceEthValue } from 'utils/formatBalance'
import { getAmountSuppliedBalance } from 'utils/reciept-base'

const PoolDetailsStats: React.FC<{ pool: DeserializedPool; children?: any }> = ({ pool, children }) => {
  const displayEarnAPY = displayBalanceEthValue(pool.apy.supply)

  return (
    <Box mt="24px" mb="auto">
      <RowBetween>
        <Text small fontWeight="400" color="textSubtle">
          Supply APY
        </Text>
        <Text>{displayEarnAPY}%</Text>
      </RowBetween>
      <Column my="8px">
        <RowBetween>
          <Text small fontWeight="400" color="textSubtle">
            Current Deposits
          </Text>
          <Text>
            {displayBalanceEthValue(getAmountSuppliedBalance(pool.userData.suppliedBalance, pool.bearingRate))}{' '}
            {pool.token.name}
          </Text>
        </RowBetween>

        <Flex justifyContent="flex-end" alignItems="center">
          <Text small color="textSubtle">
            $
            {displayBalanceEthValue(
              getAmountSuppliedBalance(pool.userData.suppliedBalance, pool.bearingRate).multipliedBy(
                pool.tokenInUsdPrice,
              ),
            )}
          </Text>
        </Flex>
      </Column>

      <Column>{children}</Column>
    </Box>
  )
}

export default PoolDetailsStats
