import Box from 'components/Box/Box'
import React, { useCallback, useMemo, useState } from 'react'

import BigNumber from 'bignumber.js'
import Text from 'components/Text/Text'
import TokenInput from 'components/TokenInput'
import { BIG_ZERO } from 'config'
import { DeserializedPool } from 'config/types/pool'
import { TokenAmount } from 'config/types/tokenAmount'
import Column from 'layout/components/Column'
import { RowBetween, RowFixed } from 'layout/components/Row'
import { displayBalanceEthValue } from 'utils/formatBalance'
import PoolAction from './PoolAction'
import SupplyDetailsStats from './PoolDetailsStats'
import YieldForecast from './YieldForecast'
import { MetamaskIcon } from 'svgs'
import { useAccount } from 'wagmi'
import useTooltip from 'hooks/useTooltip'

const PoolForm: React.FC<{ pool: DeserializedPool }> = ({ pool }) => {
  const [val, setVal] = useState('')
  const { connector, isConnected } = useAccount()

  const tokenBalance = useMemo(() => {
    return new TokenAmount(pool.token, pool.userData.tokenBalance.toString(10))
  }, [pool])

  const handleChange = useCallback(
    (value: string) => {
      setVal(value)
    },
    [setVal],
  )

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    <Text>add {pool.suppliedToken.symbol} to your Metamask</Text>,
    {
      placement: 'top',
      trigger: 'hover',
      background: 'rgb(35 46 67 / 88%)',
    },
  )

  return (
    <Column flex={1}>
      <Column flex={1}>
        <TokenInput value={val} token={pool.token} balance={tokenBalance} onUserInput={handleChange} />
        <SupplyDetailsStats pool={pool}>
          <RowBetween>
            <Text small fontWeight="400" color="textSubtle">
              Mint
            </Text>
            <RowFixed>
              <Text>
                {displayBalanceEthValue(pool.bearingRate.multipliedBy(val || BIG_ZERO))} {pool.suppliedToken.symbol}
              </Text>
              {isConnected && (
                <Box
                  position="relative"
                  ref={targetRef}
                  ml="6px"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    connector?.watchAsset?.({
                      address: pool.suppliedToken.address,
                      symbol: pool.suppliedToken.symbol,
                      decimals: pool.suppliedToken.decimals,
                      // image,
                    })
                  }}
                >
                  <MetamaskIcon width="16px" />

                  {tooltipVisible && tooltip}
                </Box>
              )}
            </RowFixed>
          </RowBetween>
        </SupplyDetailsStats>
      </Column>

      <Box my={['12px', '', '24px']}>
        <YieldForecast amountDeposit={new BigNumber(val).gt(0) ? new BigNumber(val) : BIG_ZERO} pool={pool} />
      </Box>
      <PoolAction
        pool={pool}
        amount={val}
        onSuccess={() => {
          handleChange('')
        }}
      />
    </Column>
  )
}

export default PoolForm
