import { DeserializedPool } from 'config/types/pool'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useLiquidityPoolContract } from 'hooks/useContract'
import { useCallback } from 'react'
import { getDecimalAmount } from 'utils/formatBalance'

export const useWithdraw = (pool: DeserializedPool) => {
  const { account, chainId } = useActiveWeb3React()
  const liquidityPoolContract = useLiquidityPoolContract(chainId, true)

  const handleWithdraw = useCallback(
    async (amount: string) => {
      return liquidityPoolContract
        .removeLiquidity(
          pool.token.address,
          getDecimalAmount(amount, pool.suppliedToken.decimals).toString(10),
          account,
        )
        .catch((error) => {
          console.log(error)
        })
    },
    [liquidityPoolContract],
  )

  return { onWithdraw: handleWithdraw }
}
